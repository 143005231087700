<template>
  <head>
    <title>Garage Connect - Accueil</title>
    <meta charset="utf-8">
  </head>
  <div class="container mt-5">
    <h1 class="navbar-brand text-secondary mb-3">
      GARAGE<strong>CONNECT</strong>
    </h1>
    <h2>Votre véhicule</h2>
    <div v-if="customer" class="card mt-4 p-5">
      <div class="card-body">
        <h5 class="card-title mb-5">Plaque d'immatriculation<br>
          <strong style="font-size: 1.5rem;">{{ customer.plate }}</strong>
        </h5>

        <h4 class="card-text mb-4">
          <strong>Statut :</strong>
          <br>
          <strong class="status" :class="`text-${statusColors[customer.status]}`">
            {{ statusLabels[customer.status] }}
          </strong>
        </h4>

        <p class="card-text" v-if="customer.email">
          <strong>Email :</strong>
          <br>
          <span>{{ customer.email }}</span>
        </p>

        <p class="card-text" v-if="customer.phone">
          <strong>Téléphone :</strong>
          <br>
          <span>{{ customer.phone }}</span>
        </p>

        <p class="card-text" v-if="customer.lastname">
          <strong>Nom :</strong>
          <br>
          <span>{{ customer.lastname }}</span>
        </p>

        <p class="card-text" v-if="customer.firstname">
          <strong>Prénom :</strong>
          <br>
          <span>{{ customer.firstname }}</span>
        </p>
      </div>
    </div>
    <div v-else class="alert alert-warning">Client non trouvé.</div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from 'vue';
import { useRoute } from 'vue-router';
import { CustomerService } from '@/services/apiService';

// État pour stocker le client
const customer = ref(null);
const route = useRoute();
let intervalId = null;

// Labels des statuts des clients
const statusLabels = {
  1: "Pris en compte",
  2: "En attente de pièces",
  3: "Terminé",
};

const statusColors = {
  1: "primary",
  2: "warning",
  3: "success",
};

// Fonction pour récupérer les informations du client
const fetchCustomer = async (id) => {
  try {
    const response = await CustomerService.getCustomerById(id);
    customer.value = response.data;
  } catch (err) {
    console.error('Erreur lors de la récupération du client', err);
  }
};

// Appel lors du montage du composant
onMounted(() => {
  const customerId = route.params.id;
  fetchCustomer(customerId);

  // Mettre à jour toutes les 15 secondes
  intervalId = setInterval(() => {
    fetchCustomer(customerId);
  }, 15000);
});

// Supprimer l'intervalle lors du démontage
onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style scoped>
h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.card {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
}

.card-text {
  font-size: 1.1rem;
}

.status {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.alert {
  margin-top: 20px;
}

.navbar-brand{
  font-size: 2rem;
}
</style>
