<template>
  <NavbarComponent v-if="isAuthenticated" :isAuthenticated="isAuthenticated" :isAdmin="isAdmin" :key="count"/>
  <div>
    <router-view />
  </div>
</template>

<script setup>
import {ref, onMounted, watch, onUnmounted} from 'vue';
import NavbarComponent from "@/components/NavbarComponent.vue";
import { AuthService } from '@/services/authService';
import {useRoute} from "vue-router";
const isAuthenticated = ref(false);
const isAdmin = ref(false);
const isLoading = ref(false);

const count = ref(0);

const route = useRoute();

let intervalId = null;

const updateAuthenticationStatus = async () => {
  isAuthenticated.value = await AuthService.isAuthenticated();
};

onMounted(async () => {
  isLoading.value = true;
  await updateAuthenticationStatus();
  isLoading.value = false;

  intervalId = setInterval(async () => {
    await updateAuthenticationStatus();
  }, 15000);
});

watch(route, async () => {
  await updateAuthenticationStatus();
});

onUnmounted(async () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
</style>
