// src/services/apiService.js
import apiClient from './apiClient';
import axios from "axios";

// User API
export const UserService = {
    getAllUsers(page = 1, limit = 15, search = "") {
        return apiClient.get('/api/users', {
            params: {
                page,
                limit,
                search
            }
        });
    },
    getUserById(id) {
        return apiClient.get(`/api/users/${id}`);
    },
    createUser(userData) {
        return apiClient.post('/api/users', userData);
    },
    updateUser(id, userData) {
        return apiClient.put(`/api/users/${id}`, userData);
    },
    deleteUser(id) {
        return apiClient.delete(`/api/users/${id}`);
    },
};

// Customer API
export const CustomerService = {
    getAllCustomers() {
        return apiClient.get('/api/customers');
    },
    getCustomerById(id) {
        return apiClient.get(`/customers/${id}`);
    },
    createCustomer(customerData) {
        return apiClient.post('/api/customers', customerData);
    },
    updateCustomer(id, customerData) {
        return apiClient.put(`/api/customers/${id}`, customerData);
    },
    deleteCustomer(id) {
        return apiClient.delete(`/api/customers/${id}`);
    },
};
