// src/services/authService.js
import apiClient from './apiClient';

export const AuthService = {
    async login(email, password) {
        try {
            const response = await apiClient.post('/api/login_check', {
                username: email,
                password,
            });
            localStorage.setItem('token', response.data.token);
            this.setAxiosToken();
        } catch (error) {
            console.error('Erreur de login', error);
            throw new Error(error.response ? error.response.data.message : 'Login échoué');
        }
    },

    async logout() {
        localStorage.removeItem('token');
        delete apiClient.defaults.headers.common['Authorization'];
    },

    async me() {
        try {
            const response = await apiClient.get('/api/me');
            return response.data.id ? response.data : null;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await this.logout();
                return null;
            } else {
                console.error('Erreur de récupération des informations utilisateur', error);
                return null;
            }
        }
    },

    setAxiosToken() {
        const token = localStorage.getItem('token');
        if (token) {
            apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        console.log('Token configuré', apiClient.defaults.headers.common['Authorization']);
    },

    async isAuthenticated() {
        const token = localStorage.getItem('token');
        if (token) {
            this.setAxiosToken();
            const user = await this.me();
            if (!user) {
                await this.logout();
                return false;
            }
        }
        return !!(token && token.length > 0);
    },
};

// Si un token existe déjà dans le localStorage, on configure Axios pour toutes les requêtes
if (localStorage.getItem('token')) {
    AuthService.setAxiosToken();
}
