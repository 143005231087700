<template>
  <div class="container d-flex flex-column justify-content-center align-items-center" style="height:90vh">
    <!-- Titre GARAGECONNECT au-dessus de la carte de connexion -->
    <h1 class="navbar-brand text-secondary mb-5">
      GARAGE<strong>CONNECT</strong>
    </h1>
    <div class="login card p-5" style="width: 100%;">
      <form @submit.prevent="submitLogin">
        <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p> <!-- Affichage du message d'erreur -->
        <div class="mb-3">
          <label for="email" class="form-label">Adresse e-mail:</label>
          <input v-model="email" type="email" id="email" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Mot de passe:</label>
          <input v-model="password" type="password" id="password" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary w-100">Se connecter</button>
      </form>
    </div>
  </div>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import { useRouter } from 'vue-router';
import { AuthService } from '@/services/authService';

// Initialiser les données email et password en utilisant ref()
const email = ref('');
const password = ref('');
const errorMessage = ref(null); // Message d'erreur pour l'affichage

// Utiliser le router pour la navigation
const router = useRouter();

// Méthode pour gérer le login
const submitLogin = async () => {
  errorMessage.value = null; // Réinitialiser le message d'erreur
  try {
    await AuthService.login(email.value, password.value);
    console.log('Login successful!'); // Message de succès (pour le débogage)
    await router.push({ name: 'home' }); // Redirection après login réussi
  } catch (error) {
    errorMessage.value = 'Votre adresse email ou mot de passe est incorrect.'; // Message d'erreur
  }
};
</script>

<style scoped>
.login {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.30);
}

.login h1 {
  margin-bottom: 20px;
}

.login div {
  margin-bottom: 10px;
}

.login label {
  display: block;
  margin-bottom: 5px;
  text-align: left; /* Alignement à gauche */
}

.login input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.login button {
  width: 100%;
  padding: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login button:hover {
  background-color: #38a77c;
}

.navbar-brand{
  font-size: 2rem;
}

.error {
  color: red;
  margin-bottom: 10px;
}
</style>
