<template>
  <head>
    <title>Garage Connect - Administration</title>
    <meta charset="utf-8">
  </head>
  <div class="container-fluid px-5 py-4" v-if="!isLoading">
    <h1 class="navbar-brand text-secondary mb-3 mt-4">
      GARAGE<strong>CONNECT</strong>
    </h1>
    <h2 class="mb-4">Administration</h2>

    <div id="tableFilters">
      <div class="d-flex justify-content-between mb-3">
        <!-- Ajout du champ de recherche et de la limite des éléments -->
        <div class="d-flex">
          <!-- Sélecteur de limite d'éléments par page -->
          <select id="limit" class="form-select form-select-sm" @change="changeLimit" v-model="pagination.limit">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        </div>

        <!-- Bouton pour ajouter un utilisateur -->
        <div class="d-flex justify-content-between">
          <!-- Champ de recherche -->
          <input type="text" class="form-control form-control-sm me-2" v-model="searchQuery" placeholder="Rechercher..." @input="filterUsers" />
          <button class="btn btn-primary btn-sm" @click="openModal()">
            <i class="fas fa-user-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>Label</th>
          <th>Rôles</th>
          <th>SMS (envoyés / limite)</th>
          <th>Emails envoyés</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.label }}</td>
          <td>{{ user.roles.join(', ') }}</td>
          <td :class="smsStatusClass(user.sms.limit - user.sms.count)">
            {{ user.sms.count }} / {{ user.sms.limit }}
          </td>
          <td>{{ user.emailCount }}</td>
          <td>
            <button class="btn btn-primary btn-sm me-2" @click="openModal(user)">
              <i class="fas fa-user-edit"></i>
            </button>
            <button class="btn btn-danger btn-sm" @click="deleteUser(user.id)">
              <i class="fas fa-user-times"></i>
            </button>
          </td>
        </tr>
        <tr v-if="users.length === 0">
          <td colspan="5" class="text-center">Aucun utilisateur trouvé.</td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination controls -->
    <div class="d-flex justify-content-between">
      <button class="btn btn-secondary" @click="changePage(pagination.current_page - 1)" :disabled="pagination.current_page === 1">
        Précédent
      </button>
      <span>Page {{ pagination.current_page }} sur {{ pagination.total_pages }}</span>
      <button class="btn btn-secondary" @click="changePage(pagination.current_page + 1)" :disabled="pagination.current_page === pagination.total_pages">
        Suivant
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center" style="height: 80vh;" v-else>
    <div class="spinner-border text-primary" role="status" v-if="isLoading">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>

  <!-- Modal pour ajouter ou modifier un utilisateur -->
  <div v-if="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background: rgba(0,0,0,0.5);">
    <div class="modal-dialog modal-dialog-customer" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ editMode ? 'Modifier un utilisateur' : 'Ajouter un utilisateur' }}</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveUser">
            <div class="row g-3">
              <div class="col-md-6">
                <label for="label" class="form-label">Label</label>
                <input v-model="newUser.label" id="label" type="text" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
                <input v-model="newUser.email" id="email" type="email" class="form-control" required />
              </div>
              <div class="col-md-12">
                <label for="roles" class="form-label">Rôles <span class="text-danger">*</span></label>
                <select v-model="newUser.roles" id="roles" class="form-select" multiple required>
                  <option value="ROLE_USER">Utilisateur</option>
                  <option value="ROLE_ADMIN">Administrateur</option>
                </select>
              </div>
              <div class="col-md-12">
                <label for="password" class="form-label">Mot de passe <span class="text-danger">*</span></label>
                <input v-model="newUser.password" id="password" type="password" class="form-control" :required="!editMode" />
              </div>
              <div class="col-md-12">
                <label for="confirmPassword" class="form-label">Confirmer le mot de passe <span class="text-danger">*</span></label>
                <input v-model="confirmPassword" id="confirmPassword" type="password" class="form-control" :required="!editMode" />
                <small v-if="passwordMismatch" class="text-danger">Les mots de passe ne correspondent pas</small>
              </div>
            </div>

            <div class="modal-footer mt-3">
              <button type="button" class="btn btn-danger" @click="closeModal">Annuler</button>
              <button type="submit" class="btn btn-success" :disabled="passwordMismatch && !editMode">
                {{ editMode ? 'Mettre à jour' : 'Ajouter' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import {AuthService} from '@/services/authService';
import {useRouter} from 'vue-router';
import {UserService} from "@/services/apiService";

const isLoading = ref(true);

const router = useRouter();

// Variables et états initiaux
const users = ref([]);
const searchQuery = ref("");
const pagination = ref({
  total_items: 0,
  current_page: 1,
  total_pages: 1,
  limit: 15
});
const newUser = ref({
  email: '',
  roles: [],
  label: '',
  password: ''
});
const confirmPassword = ref('');
const showModal = ref(false);
const editMode = ref(false);

// Calcul pour vérifier si les mots de passe correspondent
const passwordMismatch = computed(() => newUser.value.password !== confirmPassword.value);

// Méthodes liées à l'affichage et à l'UI
const smsStatusClass = (smsRemaining) => {
  if (smsRemaining < 50) {
    return 'text-danger';
  } else if (smsRemaining < 100) {
    return 'text-warning';
  } else {
    return 'text-success';
  }
};

const openModal = (user = null) => {
  if (user) {
    editMode.value = true;
    newUser.value = {...user, password: ''};
  } else {
    editMode.value = false;
    newUser.value = {email: '', roles: [], label: '', password: ''};
  }
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

// Méthodes pour gérer les utilisateurs
const saveUser = async () => {
  try {
    if (editMode.value) {
      const userData = {...newUser.value};
      if (!userData.password) {
        delete userData.password;
      }
      await UserService.updateUser(newUser.value.id, userData);
    } else {
      if (!passwordMismatch.value) {
        await UserService.createUser(newUser.value);
      }
    }
    closeModal();
    newUser.value = {email: '', roles: [], password: ''};
    confirmPassword.value = '';
    await fetchUsers();
  } catch (error) {
    console.error('Erreur lors de la sauvegarde de l\'utilisateur', error);
  }
};

const deleteUser = async (userId) => {
  try {
    await UserService.deleteUser(userId);
    console.log('User deleted:', userId);
    await fetchUsers();
  } catch (err) {
    console.error('Erreur lors de la suppression:', err);
  }
};

// Méthodes pour gérer la pagination et les filtres
const changeLimit = async () => {
  pagination.value.current_page = 1;
  await fetchUsers();
};

const changePage = async (page) => {
  if (page > 0 && page <= pagination.value.total_pages) {
    await fetchUsers(page);
  }
};

const filterUsers = async () => {
  pagination.value.current_page = 1;
  await fetchUsers();
};

// Méthode pour récupérer les utilisateurs
const fetchUsers = async (page = 1) => {
  try {
    const {data} = await UserService.getAllUsers(page, pagination.value.limit, searchQuery.value);
    users.value = data.data;
    pagination.value = data.pagination;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs :", error);
  }
};

// Récupérer les clients au montage du composant
onMounted(async () => {
  isLoading.value = true;
  const user = await AuthService.me();
  if (!user) {
    await router.push({name: 'login'});
  }
  if (!user.roles.includes('ROLE_ADMIN')) {
    await router.push({name: 'home'});
  }
  await fetchUsers();
  isLoading.value = false;
});
</script>

<style scoped>
.modal-dialog-customer {
  min-width: 40vw;
}

.modal-dialog-verify {
  min-width: 25vw;
}

h2 {
  font-size: 1.5rem;
  color: #2c3e50;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.0rem;
  margin-top: 5px;
  border-radius: 10px;
  cursor: grab;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  padding: 15px 50px;
  background-color: #f1f1f1;
}

li:active {
  cursor: grabbing;
}

li:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
  background-color: #eaeaea;
}

.modal {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  width: 100vw;
}

.navbar-brand{
  font-size: 2rem;
}
</style>
