// src/services/apiClient.js

import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://apigarageconnect.us-app-01.eu/',
    // baseURL: 'http://127.0.0.1:8000/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

// apiClient.interceptors.response.use(
//     response => response, // Si la réponse est correcte, on la retourne telle quelle
//     error => {
//         // Si une erreur est présente et que c'est une erreur 401 (Unauthorized)
//         if (error.response && error.response.status === 401) {
//             // Appelle la méthode logout pour déconnecter l'utilisateur
//             AuthService.logout();  // Utilisation de AuthService.logout() explicitement
//             // Redirige vers la page de login si nécessaire
//             router.push('/login'); // Utilisation de router.push() explicitement
//         }
//         return Promise.reject(error); // On rejette l'erreur pour pouvoir la traiter ailleurs si besoin
//     }
// );

export default apiClient;
