<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand text-secondary" v-if="props.isAuthenticated" to="/">
        GARAGE<strong>CONNECT</strong>
      </router-link>

      <div class="d-flex" v-if="props.isAuthenticated">
        <button class="btn btn-danger btn-sm" @click="logout">
          Déconnexion
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import {useRouter} from 'vue-router';
import { defineProps } from "vue";
import {AuthService} from "@/services/authService";

// Récupération des props
const props = defineProps({
  isAuthenticated: Boolean,
  isAdmin: Boolean
});

// Accès au routeur
const router = useRouter();

// Fonction pour gérer le logout
const logout = async () => {
  await AuthService.logout();
  await router.push({name: 'login'});
};
</script>

<style scoped>
nav {
  padding: 20px 50px;
}

.navbar-brand {
  font-weight: bold;
}

.btn {
  margin-left: 15px;
}
</style>
